.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.horizontal-product-slider::-webkit-scrollbar {
  display: none;
}

:root {
  --shopping-cart-red: #2C971F; 
}

.header-cart[data-count]:after{
  position:absolute;
  right:0%;
  top:0%;
  content: attr(data-count);
  font-size:40%;
  padding:.5em;
  border-radius:999px;
  line-height:.75em;
  color: white;
  text-align:center;
  min-width:2em;
  font-weight:bold;
  background: white;
  border-style:solid;
  color: var(--shopping-cart-red);
}

.header-cart-circle {
  color:#2C971F;
}


.sphere {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #EBEBEB; /* Puedes cambiar este color según tus preferencias */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.connector {
  width: 40px;
  height: 5px;
  background-color: #EBEBEB; /* Puedes cambiar este color según tus preferencias */
}

.number {
  color: white;
  font-size: 20px;
}
