body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobileMenuRight {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: translateX(-100%);
  transition: all ease 0.25s;
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.mobileMenuRight.open {
  transform: translateX(0);
}

.HorizontalContainer {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.horizontalSliderCard {
  flex-shrink: 0;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  margin-left: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.scrolling-wrapper{
	overflow-x: auto;
}

/* body{
	background-color: black;
	color: #fff;
	font-family: 'system-ui';
}

h1{
	font-weight: 700;
	font-size: 3.4em;
}

.subtitle{
	font-size: 1.25em;
	opacity: 0.65;
} */

.card-block{
	height: 300px;
	background-color: #fff;
	border: none;
	background-position: center;
	background-size: cover;
	transition: all 0.2s ease-in-out !important;
	border-radius: 24px;
	&:hover{
		transform: translateY(-5px);
		box-shadow: none;
		opacity: 0.9;
	}
}

.card-1{
	background-color: #4158D0;
background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.card-2{
	background-color: #0093E9;
background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}

.card-3{
	background-color: #00DBDE;
background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
}

.card-4{
	background-color: #FBAB7E;
background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
}

.card-5{
	background-color: #85FFBD;
background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
}

.card-6{
	background-color: #FA8BFF;
background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);
}

.card-7{
	background-color: #FA8BFF;
background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);
}

.card-8{
	background-color: #FBDA61;
background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.card-9{
	background-color: #4158D0;
background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.card-10{
	background-color: #FF3CAC;
background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);

}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.flip {
	backface-visibility: visible !important;
	animation: flip 2s ease infinite;
}
@keyframes flip {
	0% {
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		transform: perspective(400px) rotateY(360deg) scale(.95);
		animation-timing-function: ease-in;
	}
	100% {
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}

delivery-body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
	background-color: #02162b;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .timeline-main {
	width: 100%;
	padding: 25px;
	.timeline-wrap {
	  position: relative;
	  display: flex;
	  flex-direction: column;
	  gap: 30px;
	  &::before {
		content: "";
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 4px;
		height: calc(100% - 10px);
		background-color: #00283a;
		animation: line 5s linear;
		@media (max-width: 767px) {
		  left: 95%;
		}
	  }
	  .timeline-card {
		width: 50%;
		margin-right: auto;
		position: relative;
		@media (max-width: 767px) {
		  width: 95%;
		}
		&::after {
		  content: "";
		  position: absolute;
		  top: 10px;
		  right: -10px;
		  width: 20px;
		  height: 20px;
		  border-radius: 50%;
		  border: 3px solid #fff;
		  background: #00283a;
		  animation: fadeIn 1.5s;
		  animation-fill-mode: both;
		}
		.timeline-card-wrap {
		  height: 100%;
		  margin-right: 35px;
		  background-color: #00283a;
		  border-radius: 10px;
		  position: relative;
		  padding: 30px;
		  animation: fadeInRight 2.5s;
		  animation-fill-mode: both;
		  @media (max-width: 767px) {
			padding: 20px;
		  }
		  &::before {
			content: "";
			position: absolute;
			left: 30px;
			top: -8px;
			height: 8px;
			width: calc(100% - 60px);
			background-color: #00283a;
			border-radius: 5px 5px 0 0;
			opacity: 0.3;
		  }
		  &::after {
			content: "";
			position: absolute;
			top: 10px;
			right: -8px;
			width: 20px;
			height: 20px;
			background-color: #00283a;
			border-radius: 5px;
			transform: rotate(45deg);
		  }
		  .card-head-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			@media (max-width: 1199px) {
			  flex-direction: column;
			  align-items: flex-start;
			  gap: 10px;
			}
			.timeline-card-head {
			  font-size: 28px;
			  line-height: 38px;
			  font-weight: 500;
			  color: #fff;
			  margin-bottom: 0;
			  @media (max-width: 767px) {
				font-size: 18px;
				line-height: 28px;
			  }
			}
			.timeline-card-subhead {
			  font-size: 16px;
			  line-height: 26px;
			  font-weight: 400;
			  color: #bdbdbd;
			  margin-bottom: 0;
			  font-style: italic;
			  @media (max-width: 767px) {
				font-size: 14px;
				line-height: 24px;
			  }
			}
		  }
  
		  .timeline-card-text {
			font-size: 14px;
			line-height: 24px;
			font-weight: 400;
			color: #919ca1;
			margin: 25px 0 0;
			@media (max-width: 767px) {
			  font-size: 12px;
			  line-height: 22px;
			}
		  }
		}
  
		&:nth-child(even) {
		  @media (min-width: 768px) {
			margin-left: auto;
			margin-right: 0;
			&::after {
			  right: unset;
			  left: -10px;
			  animation-delay: 2.6s;
			}
			.timeline-card-wrap {
			  margin-right: 0;
			  margin-left: 35px;
			  animation: fadeInLeft 2.5s;
			  animation-fill-mode: both;
			  animation-delay: 2.5s;
			  &::after {
				right: unset;
				border-left: none;
				left: -8px;
				border-right: 10px solid #00283a;
			  }
			}
		  }
		}
	  }
	}
  }
  @keyframes line {
	0% {
	  height: 0;
	}
  
	100% {
	  height: calc(100% - 10px);
	}
  }
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
  
	to {
	  opacity: 1;
	}
  }
  @keyframes fadeInRight {
	0% {
	  opacity: 0;
	  transform: translateX(20px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  @keyframes fadeInLeft {
	0% {
	  opacity: 0;
	  transform: translateX(-20px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }

.user-info-tab.nav-link.active {
	color: #29BC5A !important;
	background-color: #F8F9FA !important;
	border-bottom: 1px solid black;
}
  